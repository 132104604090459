// extracted by mini-css-extract-plugin
export var pd20px = "tagCard-module--pd20px--MZQs3";
export var rsBadgeBlue = "tagCard-module--rs-badge-blue--k5wUn";
export var rsBadgeContent = "tagCard-module--rs-badge-content--fdP73";
export var rsBadgeCyan = "tagCard-module--rs-badge-cyan--YRgFj";
export var rsBadgeGreen = "tagCard-module--rs-badge-green--24neM";
export var rsBadgeIndependent = "tagCard-module--rs-badge-independent--qDCIX";
export var rsBadgeOrange = "tagCard-module--rs-badge-orange--v2ydj";
export var rsBadgeRed = "tagCard-module--rs-badge-red--qPiqX";
export var rsBadgeViolet = "tagCard-module--rs-badge-violet--dL3K0";
export var rsBadgeYellow = "tagCard-module--rs-badge-yellow--rMq48";
export var rsBtnBlue = "tagCard-module--rs-btn-blue--ejpIT";
export var rsBtnCyan = "tagCard-module--rs-btn-cyan--HKf6-";
export var rsBtnGreen = "tagCard-module--rs-btn-green--BqVmG";
export var rsBtnOrange = "tagCard-module--rs-btn-orange--Q3oLw";
export var rsBtnRed = "tagCard-module--rs-btn-red--7rSur";
export var rsBtnViolet = "tagCard-module--rs-btn-violet--3OBYU";
export var rsBtnYellow = "tagCard-module--rs-btn-yellow--yEfhn";
export var rsFlexBoxGridItem1 = "tagCard-module--rs-flex-box-grid-item-1--2yfq0";
export var rsFlexBoxGridItem10 = "tagCard-module--rs-flex-box-grid-item-10--l3tDq";
export var rsFlexBoxGridItem11 = "tagCard-module--rs-flex-box-grid-item-11--4Q7Sa";
export var rsFlexBoxGridItem12 = "tagCard-module--rs-flex-box-grid-item-12--Ftnfk";
export var rsFlexBoxGridItem13 = "tagCard-module--rs-flex-box-grid-item-13--FCgfI";
export var rsFlexBoxGridItem14 = "tagCard-module--rs-flex-box-grid-item-14--zYxxw";
export var rsFlexBoxGridItem15 = "tagCard-module--rs-flex-box-grid-item-15--4tOm3";
export var rsFlexBoxGridItem16 = "tagCard-module--rs-flex-box-grid-item-16--7twWa";
export var rsFlexBoxGridItem17 = "tagCard-module--rs-flex-box-grid-item-17--zNy6n";
export var rsFlexBoxGridItem18 = "tagCard-module--rs-flex-box-grid-item-18--yuNoq";
export var rsFlexBoxGridItem19 = "tagCard-module--rs-flex-box-grid-item-19--zCJBd";
export var rsFlexBoxGridItem2 = "tagCard-module--rs-flex-box-grid-item-2--BhYnS";
export var rsFlexBoxGridItem20 = "tagCard-module--rs-flex-box-grid-item-20--a+Rdt";
export var rsFlexBoxGridItem21 = "tagCard-module--rs-flex-box-grid-item-21--CCoFG";
export var rsFlexBoxGridItem22 = "tagCard-module--rs-flex-box-grid-item-22--iKCz3";
export var rsFlexBoxGridItem23 = "tagCard-module--rs-flex-box-grid-item-23--wSB5v";
export var rsFlexBoxGridItem24 = "tagCard-module--rs-flex-box-grid-item-24--w5LPi";
export var rsFlexBoxGridItem3 = "tagCard-module--rs-flex-box-grid-item-3--dcvsB";
export var rsFlexBoxGridItem4 = "tagCard-module--rs-flex-box-grid-item-4--xlKD+";
export var rsFlexBoxGridItem5 = "tagCard-module--rs-flex-box-grid-item-5--myL9E";
export var rsFlexBoxGridItem6 = "tagCard-module--rs-flex-box-grid-item-6--7I9RC";
export var rsFlexBoxGridItem7 = "tagCard-module--rs-flex-box-grid-item-7--RJiE4";
export var rsFlexBoxGridItem8 = "tagCard-module--rs-flex-box-grid-item-8--thtpA";
export var rsFlexBoxGridItem9 = "tagCard-module--rs-flex-box-grid-item-9--Cb2zw";
export var rsFlexBoxGridItemOrder1 = "tagCard-module--rs-flex-box-grid-item-order-1--midVk";
export var rsFlexBoxGridItemOrder10 = "tagCard-module--rs-flex-box-grid-item-order-10--IbSap";
export var rsFlexBoxGridItemOrder11 = "tagCard-module--rs-flex-box-grid-item-order-11--cc4Ml";
export var rsFlexBoxGridItemOrder12 = "tagCard-module--rs-flex-box-grid-item-order-12--bAGeK";
export var rsFlexBoxGridItemOrder13 = "tagCard-module--rs-flex-box-grid-item-order-13--t+627";
export var rsFlexBoxGridItemOrder14 = "tagCard-module--rs-flex-box-grid-item-order-14--TK6UU";
export var rsFlexBoxGridItemOrder15 = "tagCard-module--rs-flex-box-grid-item-order-15---9Aue";
export var rsFlexBoxGridItemOrder16 = "tagCard-module--rs-flex-box-grid-item-order-16--c0-Yr";
export var rsFlexBoxGridItemOrder17 = "tagCard-module--rs-flex-box-grid-item-order-17--0gItq";
export var rsFlexBoxGridItemOrder18 = "tagCard-module--rs-flex-box-grid-item-order-18--SyCdy";
export var rsFlexBoxGridItemOrder19 = "tagCard-module--rs-flex-box-grid-item-order-19--DILPg";
export var rsFlexBoxGridItemOrder2 = "tagCard-module--rs-flex-box-grid-item-order-2--BBXgz";
export var rsFlexBoxGridItemOrder20 = "tagCard-module--rs-flex-box-grid-item-order-20--rcgAj";
export var rsFlexBoxGridItemOrder21 = "tagCard-module--rs-flex-box-grid-item-order-21--l0F4B";
export var rsFlexBoxGridItemOrder22 = "tagCard-module--rs-flex-box-grid-item-order-22--jfX4r";
export var rsFlexBoxGridItemOrder23 = "tagCard-module--rs-flex-box-grid-item-order-23--356+Q";
export var rsFlexBoxGridItemOrder24 = "tagCard-module--rs-flex-box-grid-item-order-24--AuOEd";
export var rsFlexBoxGridItemOrder3 = "tagCard-module--rs-flex-box-grid-item-order-3--i+fRC";
export var rsFlexBoxGridItemOrder4 = "tagCard-module--rs-flex-box-grid-item-order-4--pwul6";
export var rsFlexBoxGridItemOrder5 = "tagCard-module--rs-flex-box-grid-item-order-5--xb-8L";
export var rsFlexBoxGridItemOrder6 = "tagCard-module--rs-flex-box-grid-item-order-6--WmFTs";
export var rsFlexBoxGridItemOrder7 = "tagCard-module--rs-flex-box-grid-item-order-7--Io0LI";
export var rsFlexBoxGridItemOrder8 = "tagCard-module--rs-flex-box-grid-item-order-8--j0hBp";
export var rsFlexBoxGridItemOrder9 = "tagCard-module--rs-flex-box-grid-item-order-9--5NbU-";
export var rsTagBlue = "tagCard-module--rs-tag-blue--FxpfT";
export var rsTagCyan = "tagCard-module--rs-tag-cyan--Xplsn";
export var rsTagGreen = "tagCard-module--rs-tag-green--SAEFx";
export var rsTagOrange = "tagCard-module--rs-tag-orange--96Uz1";
export var rsTagRed = "tagCard-module--rs-tag-red--6Q82T";
export var rsTagViolet = "tagCard-module--rs-tag-violet--8Rt4h";
export var rsTagYellow = "tagCard-module--rs-tag-yellow--Zw9wD";
export var rsThemeDark = "tagCard-module--rs-theme-dark--mvjaV";
export var rsThemeHighContrast = "tagCard-module--rs-theme-high-contrast--5joPC";
export var tagCard = "tagCard-module--tagCard--Iqco8";
export var tagImg = "tagCard-module--tagImg--ikgdw";